.body{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    align-self: center;
    flex-wrap: wrap;
    padding: 1em;

    .title{
        display: flex;
        align-items: center;
    }

    .select{
        display: flex;
        flex-direction: column;
        align-items: center;    
        width: 140px;
        height: 129px;
        background-color:#D9D9D9;
        font-size: 13px;
        color:white;
        margin: 1em;
        border-radius: 15px;
        box-shadow: 0px 4px 4px 0px #00000040;

        .circle{
            display: flex !important;
            width: 65px;
            height: 65px;
            border-radius: 50%;
            background-color: white;
            color: #4EBA74;
            font-size: 20px;
            font-weight: 800;
            align-items: center !important;
            justify-content: center !important;
            margin-top: 10px;         
        }

        .link{
            padding: 1em 0;
            color: white;
            width: 100px;
            height: 50px;
            text-align: center;
            font-weight: 800;
        }
    }


    .card{
        width: 100%;
        height: 300px;
        max-width: 400px;
        background: white;
        display: flex;
        justify-content: center;
        box-shadow: 7px 4px 8px 0px #999999BF;
        border-radius: 5%;
        transform: translate(0,50%);
        position:absolute;
        display: flex;
        flex-direction: column;
        align-content: center;

        .field{
            margin:1em 3em;
            width: 274px;
            height: 48px;
        }

        .button{
            margin:1em 48px;
            width: 274px;
            height: 48px;
            align-content: center;
            background-color:#4EBA74;
        }
    }
}
