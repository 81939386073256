.box{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    padding: 1em;
    width: 420px;
    height: auto;
    border-radius: 15%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 400px;
    background-color: white;
    border: 1px solid #D9D9D9;

    .img{
        padding: 2em;
    }

    .title{
        font-weight: 400;
        font-size: 16px;
        
    }

    .subtitle{
        font-size: 16px;
        color: #969696;
    }

    .button{
        background-color: #4EBA74;
        margin: 1em;
    }
}
