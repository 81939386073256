
.toolbar{
    background-color: #EFEFEF !important;
    box-shadow: none !important;
    
    .cont{
        display: flex;
        justify-content: space-between;
    }

    .logout{
        margin-right: 1em;
        .arrow{
            width: 25px;
            height: 25px;
            border-style: solid;
            border-radius: 50%;
            border: 1px solid #30694F;
            color:#30694F;
            font-size: 16px;
            font-weight: 800;
            margin-right: 5px;
        }
    }
}

.title{
    display: flex;
    justify-content: center;
}